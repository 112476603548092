import React from 'react';
// @ts-ignore
import SocialMediaButtons from 'react-social-media-buttons';
import { AnimateCC, GetAnimationObjectParameter } from './shared/AnimateCC'
import './App.css';

function App() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, getAnimationObject] = React.useState<GetAnimationObjectParameter | null>(null);


  return (
    <div className="App">
      <header className="App-header">

        <div className="logo-container">
          <AnimateCC
            animationName="waveuplogo"
            getAnimationObject={getAnimationObject}
            paused={false}
          />
        </div>

        <p>Coming soon...</p>

        <div className="links">
        <SocialMediaButtons
          links={['https://www.facebook.com/wave.up.music/','https://www.instagram.com/wave.up.music','https://soundcloud.com/waveupmusic','https://twitter.com/waveuplyon']}
          buttonStyle={{margin: '0px 10px', backgroundColor: '#282c34', border: '2px solid #ffffff'}}
          iconStyle={{color: '#ffffff'}}
          openNewTab={true}
        />
        </div>
      </header>
    </div>
  );
}

export default App;
